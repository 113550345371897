<template>
  <el-dialog
    class='addStudent-dialog'
    :title='`修改职务与联系电话${title}`'
    :visible.sync='show'
    :close-on-click-modal='false'
    width='540px'
    :before-close='cancel'>
    <el-form
      ref='form'
      :model='formData'
      :rules='rules'
      label-width='100px'
    >
      <el-form-item label='职务' prop='job'>
        <el-input v-model.trim='formData.job' />
      </el-form-item>
      <el-form-item label='联系电话' prop='fixedPhone'>
        <el-input
          v-model.trim='formData.fixedPhone' />
      </el-form-item>
    </el-form>
    <template #footer>
      <cancel-popover ref="cancelRef" :disabled.sync="loadData.loading" :update-flag="updateFlag"/>
      <el-button type='primary' @click="save('学生职务和联系电话',addStudent)">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { addStudent, getStudentById } from '@/api/student'
import dialog from '@/vue/mixins/dialog'

export default {
  mixins: [dialog],
  data () {
    return {
      formData: {
        id: '',
        job: '',
        fixedPhone: ''
      },
      rules: {
        job: this.$store.state.commonValidate.limit30WordsObj,
        fixedPhone: this.$store.state.commonValidate.validatorPhoneObj
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      if (this.id) {
        this.getDataById(this.id, getStudentById)
      }
    })
  },
  methods: {
    addStudent // 保存api
  }
}
</script>
