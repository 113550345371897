<template>
  <table-view
    :total="total"
    v-loading="loading"
    :query-info.sync="queryInfo"
    @reload-table="renderTable"
  >
    <template #header>
      <view-search-form
        ref="searchFormRef"
        keyword-placeholder="姓名、学号、录取编号"
        :queryInfo.sync="queryInfo"
        :tool-list="['grade', 'college', 'major', 'class','keyword']"
        :insert-select-all="['college']"
        @on-search="renderTable(1)" />
      <template v-if="queryInfo.classesId">
        <div class="fl" v-if="queryInfo.classInfo.stuNum" style="margin-top: 4px;">
            <span>总人数：<b>{{ queryInfo.classInfo.stuNum }}</b></span
            > (<span>男生：<b>{{ queryInfo.classInfo.manNum }}</b></span
        > <span class="font-grey">/</span> <span>女生：<b>{{ queryInfo.classInfo.womanNum }}</b></span
        >) ，<span>辅导员：<b>{{ queryInfo.classInfo.managerUserName }}</b></span
        >，
          <span>辅导员电话：{{ queryInfo.classInfo.managerUserPhone }}</span>
          <!--  只有剔除学生后，才需要更新   -->
          <el-button v-if="updateFlag" class="ml-1" size="small" type="primary" plain @click="handleUpdate"
                     style="padding-top: 5px;padding-bottom: 5px;">
            <i class="el-icon-refresh"></i>
            更新
            <tool-tips-group tips-content="剔除学生后，需要点击此按钮重新统计班级数据"
                             style="vertical-align:-2px;display: inline-block;">
            </tool-tips-group>
          </el-button>
        </div>
        <div class="header-button">
          <el-button type="success" size="small" @click="exportClassExcelHandle"
          >导出班级数据
          </el-button>
        </div>
      </template>
    </template>
    <!-- body -->
    <el-table :data="tableData" style="width: 100%" border stripe>
      <el-table-column type="index" label="ID" width="40" />
      <el-table-column prop="examineNo" label="录取编号" width="90" />
      <el-table-column prop="stuNo" label="学号" width="120" align="center">
        <template v-slot="{row}">
          {{ row.stuNo||'--' }}
        </template>
      </el-table-column>
      <el-table-column prop="grade" label="年级" width="55" />
      <el-table-column prop="stuName" label="姓名" width="100" />
      <el-table-column label="学籍状态" width="110">
        <template v-slot="{row}">
          {{ $http.getParamName(row.stuStatus, $store.state.systemParam.stuStatus) }}
        </template>
      </el-table-column>
      <el-table-column prop="sexType" label="性别" width="49">
        <template v-slot="{row}">
          {{ Number(row.sexType) === 1 ? '男' : '女' }}
        </template>
      </el-table-column>
      <el-table-column prop="collegeName" label="院系" width="150" />
      <el-table-column prop="majorName" label="专业" width="150" />
      <el-table-column prop="className" label="班级" width="150">
        <template v-slot="{row}">
          {{ row.className||'未绑定' }}
        </template>
      </el-table-column>
      <el-table-column prop="job" label="职务" width="120" />
      <el-table-column prop="apartmentName" label="宿舍" width="150">
        <template v-slot="{row}">
            <span v-if="row.dormitoryName && row.dormitoryBedNo"
            >{{ row.dormitoryName }}{{ row.roomName }}室{{
                row.dormitoryBedNo
              }}号床</span
            >
          <span v-else>未分配</span>
        </template>
      </el-table-column>
      <el-table-column prop="fixedPhone" label="联系电话" width="120">
      </el-table-column>
      <el-table-column prop="remarks" label="备注" />
      <el-table-column label="操作" width="150" fixed="right">
        <template v-slot="{row}">
          <el-button v-permission="['class:stu:edit']" type="primary" size="mini"
                     @click="showDialog(row.id, `-${row.stuName}`)">编辑
          </el-button>
          <el-button v-permission="['class:stu:clear']" size="mini" type="danger" @click="clearStudentClass(row)">剔除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <modStudentInfo ref="dialogRef" :visible.sync="visibleDialog" v-if="visibleDialog" @on-close="renderTable" />
  </table-view>
</template>

<script>
import { clearClassByStuId, getStudentList } from '@/api/student'
import modStudentInfo from './dialog/modStudentInfo.vue'
import tableView from '@/vue/mixins/table-view'

export default {
  components: {
    modStudentInfo
  },
  mixins: [tableView],
  data () {
    return {
      updateFlag: false,
      queryInfo: {
        campusId: '',
        grade: new Date().getFullYear(),
        collegeId: '',
        majorId: null,
        classesId: null,
        classInfo: {}
      }
    }
  },
  methods: {
    // 获取学生列表
    renderTable (pageNum) {
      if (this.queryInfo.classesId) {
      }
      this.getTableData(getStudentList, pageNum)
    },
    // 导出班级数据
    exportClassExcelHandle () {
      this.$http.exportExcelHttp
        .exportStudentInfo(
          {
            collegeId: this.queryInfo.collegeId,
            majorId: this.queryInfo.majorId,
            classesId: this.queryInfo.classesId
          },
          '班级数据'
        )
        .then((res) => {
        })
    },
    // 手动更新班级人数数据
    async handleUpdate () {
      try {
        this.queryInfo.classInfo = this.$refs.searchFormRef.classList.filter(item => item.id === this.queryInfo.classesId)[0]
        this.updateFlag = false
      } catch (e) {
      }
    },
    clearStudentClass (row) {
      this.$confirm(`<p class="confirm-message">此操作将清空  <b class="danger">${row.stuName}</b> 的班级，剔除后该学生将需要重新分班，是否继续?</p>`, '提示', {
        dangerouslyUseHTMLString: true,
        type: 'error'
      })
        .then(async () => {
          this.loading = true
          try {
            await clearClassByStuId(row.id)
            if (this.queryInfo.classesId) {
              this.updateFlag = true
              await this.$refs.searchFormRef.getClassList()
            }
            await this.renderTable()
          } catch (e) {
          } finally {
            this.loading = false
          }
        })
        .catch(() => {
        })
    }
  }
}
</script>
